import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar.jsx'
import Banner from '../Componentes/Banners/Banner.jsx';
import UsoDelEquipo from '../Componentes/UsoDelEquipo/UsoDelEquipo.jsx';
import SolucionDeProblemas from '../Componentes/SolucionDeProblemas/SolucionDeProblemas.jsx';
import Video from "../Componentes/Video/video.jsx";
import ManualesYDocumentos1item from '../Componentes/ManualesYDocumentos/ManualesYDocumentos1item.jsx';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales.jsx';
import Footer from '../Componentes/Footer/Footer.jsx'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal.jsx';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_SC6500 } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_SC6500 } from '../Componentes/infoJsons/infoUsoDelEquipo.js'
import { obtenerSDP_SC6500 } from '../Componentes/infoJsons/infoSoluDeProble.js';

const SC6500 = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO_SC6500, setUSO_SC6500] = useState([]);
      //
    
      //Solucion de Problemas
        const [SDP_SC6500, setSDP_SC6500] = useState([]);
      //
    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_SC6500().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_SC6500().then(uso => {setUSO_SC6500(uso);});
            //

            //SOLUCION DE PROBLEMA
            obtenerSDP_SC6500().then(SDP => {setSDP_SC6500(SDP)});
            //
        }, []);

        const listas = [
          
        ]
        
        const listasDesc2 = [
        ]



        const videos = [
            "https://nilfiskchile.cl/assets/videos/F-SC6500B/SC6500_guia_uso_mantención_español_Nilfisk.mov"
          ];
          
          const posters = [
            "https://nilfiskchile.cl/assets/videos/F-SC6500B/sc6500b-video.jpg"
          ];
          
          const thumbnails = [
            "https://nilfiskchile.cl/assets/videos/F-SC6500B/sc6500b-video.jpg"
          ];

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/ FregadoraSC6500B/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/ FregadoraSC6500B/Manuales/SC6500_ Uso_mantencion_espannol_Chile.pdf",
              label: 'Catálogo',
            }
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2}/>
            <UsoDelEquipo data={USO_SC6500}/>
            <SolucionDeProblemas problemas={SDP_SC6500}/>
            <Video videos={videos} posters={posters} thumbnails={thumbnails}  />
            <ManualesYDocumentos1item elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default SC6500;