import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar'
import Banner from '../Componentes/Banners/Banner';
import UsoDelEquipo from '../Componentes/UsoDelEquipo/UsoDelEquipo';
import AlfinalizarElTrabajo from '../Componentes/AlFinalizarElTrabajo/AlFinalizarElTrabajo';
import Video from "../Componentes/Video/video";
import ManualesYDocumentos from '../Componentes/ManualesYDocumentos/ManuealesYDocumentos';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_SW750B } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_SW750B } from '../Componentes/infoJsons/infoUsoDelEquipo.js'
import { obtenerAlfinalizarSW750B } from "../Componentes/infoJsons/infoAlFinalizar.js";

const SW750B = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO_SW750B, setUSO_SW750B] = useState([]);
      //
    
      //AL FINALIZAR EL TRABAJO
        const [final, setFinal] = useState([]);
      //
    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_SW750B().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_SW750B().then(uso => {setUSO_SW750B(uso);});
            //
    
            //AL FINALIZAR EL TRABAJO
            obtenerAlfinalizarSW750B().then(final => {setFinal(final);});
            //
        }, []);

        const listas = [
        ]
        
        const listasDesc2 = [
        ]



        const videos = [
            "https://nilfiskchile.cl/assets/videos/B-SW750B/SW750_guia_inicio_rapido_español_Nilfisk.mp4",
          ];
          
          const posters = [
            "https://nilfiskchile.cl/assets/videos/B-SW750B/sw750b-video.jpg",

          ];
          
          const thumbnails = [
            "https://nilfiskchile.cl/assets/videos/B-SW750B/sw750b-video.jpg",

          ];

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/BarredoraSW750B/Manuales/catalogo.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/BarredoraSW750B/Manuales/SW750 B_Catálogo_español_Chile.pdf",
              label: 'Catálogo',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/BarredoraSW750B/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/BarredoraSW750B/Manuales/SW750 B_Uso_mantención_español_Chile.pdf",
              label: 'Uso Y Mantencion',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/BarredoraSW750B/Manuales/ficha-tecnica.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/BarredoraSW750B/Manuales/SW750 B_Ficha Técnica_español_Chile.pdf",
              label: 'Ficha técnica',
            },
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2}/>
            <UsoDelEquipo data={USO_SW750B}/>
            <AlfinalizarElTrabajo data={final} />
            <Video videos={videos} posters={posters} thumbnails={thumbnails}  />
            <ManualesYDocumentos elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default SW750B;